import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { PlayIcon } from '@heroicons/react/20/solid';
import { useEffect } from 'react';
import useNavigation from '../common/navigation';
import ProductTourWrapper from '../components/ProductTourWrapper';
import Sidebar from '../components/Sidebar';
import { useProductTourContext } from '../context/ProductTourContext';
import RecordAudioButton from '../RecordAudioButton';

// const stats = [
//   { name: 'Offices worldwide', value: '12' },
//   { name: 'Full-time colleagues', value: '300+' },
//   { name: 'Hours per week', value: '40' },
//   { name: 'Paid time off', value: 'Unlimited' }
// ];

export default function HomePage() {
  const { instance } = useMsal();
  const activeAccount: AccountInfo | null = instance.getActiveAccount();
  const tokenClaims = activeAccount?.idTokenClaims;
  let activeUserName = 'unknown';
  if (tokenClaims) {
    activeUserName =
      (tokenClaims['given_name'] as string) + ' ' + (tokenClaims['family_name'] as string) || '';
  }

  const { goToClientsPage, goToNotesPage, goToTemplatesPage } = useNavigation();
  const links = [
    { name: 'Your Clients', id: 'home-clients-link', navigation: goToClientsPage },
    { name: 'Your Meeting Notes', id: 'home-notes-link', navigation: goToNotesPage },
    { name: 'Your Meeting Templates', id: 'home-templates-link', navigation: goToTemplatesPage }
  ];

  // ============================== PRODUCT TOUR ==============================
  const { state: tourState, setState: setTourState } = useProductTourContext();

  const startTour = () => setTourState({ ...tourState, tourActive: true, run: true, stepIndex: 0 });
  const stopTour = () => setTourState({ ...tourState, tourActive: false, run: false });
  const pauseTour = () => setTourState({ ...tourState, run: false });
  const resumeTour = () => setTourState({ ...tourState, run: true });

  const restartTour = () => {
    stopTour();
    setTimeout(() => startTour(), 200);
  };

  useEffect(() => {
    // Initialize the tour if it has never run before
    const hasRun = localStorage.getItem('productTourHasRun');
    if (!hasRun) {
      startTour();
      localStorage.setItem('productTourHasRun', 'true');
    }
    // If tour is already running, pause and resume it so it renders correctly
    if (tourState.tourActive) {
      pauseTour();
      const timer = setTimeout(() => resumeTour(), 600); // ms delay to ensure the tour is active before setting the step index.
      return () => clearTimeout(timer);
    }
  }, []);
  // ============================== END PRODUCT TOUR ===========================

  return (
    <ProductTourWrapper>
      <Sidebar currentPageName={'Home'} />
      <main className="flex-1 flex flex-col items-center lg:ml-72 h-screen">
        {/* Product Tour Button */}
        <div className="relative w-full">
          <button
            onClick={restartTour}
            className="absolute flex items-center top-6 right-6 z-50 hover:bg-indigo-400 text-sm font-semibold text-white px-3.5 py-2 rounded-lg shadow-lg bg-indigo-600 transition duration-300"
          >
            <PlayIcon className="h-3.5 w-3.5 mr-2" />
            <p>Tutorial</p>
          </button>
        </div>
        <div className="flex w-full h-full items-center">
          <div className=" max-w-7xl px-6 lg:px-8">
            {/*mx-auto*/}
            <div className="-mt-32 max-w-2xl lg:mx-0">
              <h2 className="text-4xl font-semibold tracking-tight text-gray-700 sm:text-5xl">
                Hello, <span className="font-bold bg-indigo-50">{activeUserName}</span>
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-500">
                Simplify your workflow with AI-powered note automation. Build custom meeting
                templates, access client information, and streamline paperwork, so you can focus on
                what truly matters.
              </p>
            </div>
            <div id="linkContainer" className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7  sm:grid-cols-2 md:flex lg:gap-x-10">
                {links.map((link) => (
                  <button
                    className="rounded bg-indigo-50 hover:bg-indigo-500 px-6 py-1 text-indigo-800 hover:text-white transition-color duration-300"
                    key={link.name}
                    onClick={link.navigation}
                    id={link.id}
                  >
                    {link.name} <span aria-hidden="true">&rarr;</span>
                  </button>
                ))}
              </div>
              {/* <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((stat) => (
                <div key={stat.name} className="flex flex-col-reverse">
                  <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                  <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                    {stat.value}
                  </dd>
                </div>
              ))}
            </dl> */}
            </div>
          </div>
        </div>
        <RecordAudioButton />
      </main>
    </ProductTourWrapper>
  );
}
