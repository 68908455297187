import { Step } from 'react-joyride';

export class ApiError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    this.name = 'ApiError';
  }
}

export type Client = {
  id: number;
  name: string;
};

export type User = {
  id: number;
  name: string;
  email: string;
};

export enum ProcessingStatus {
  NOT_STARTED = 'NOT_STARTED',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
  COMPLETED = 'COMPLETED'
}

export type Meeting = {
  id?: number; // optional because we don't pass in an id on creation
  createdAt: Date;
  worker?: string;
  dateTime: Date;
  client: Client;
  location: string;
  meetingTemplateId?: number; // Id of a meetingTemplate object if recording uploaded and processed into notes
  // isProcessed?: boolean;
  status: ProcessingStatus;
  title: string;
};

export type MeetingServer = {
  id?: number;
  createdAt: Date;
  formId: number;
  userId: number;
  worker: string;
  // isProcessed?: boolean;
  client: Client;
  location: string;
  dateTime: string;
  transcriptSource?: string;
  status: ProcessingStatus;
  title: string;
};

// Please keep this in sync with mobile!
export enum LanguageCode {
  ENGLISH = 'en',
  SPANISH = 'es',
  SWAHILI = 'sw'
}

export type CreateMeetingRequest = {
  dateTime: string;
  clientId: number;
  location: string;
  formId: number; // Id of a meetingTemplate object if recording uploaded and processed into notes
  primaryLanguage: LanguageCode;
};

// Basic data that is rendered in notes page
export type BasicMeetingData = {
  worker: string;
  date: string;
  time: string;
  client: Client;
  location: string;
  template: Template;
};

export type CalendarDate = {
  date: string;
  isCurrentMonth?: boolean;
  isToday?: boolean;
  isSelected?: boolean;
};

export type Template = {
  id?: number;
  userId?: number;
  title: string;
  createdAt?: string;
  updatedAt?: string;
};

export enum TemplateElements {
  SingleSelect = 'SingleSelect',
  MultiSelect = 'MultiSelect',
  ShortAnswer = 'ShortAnswer',
  ShortAnswerList = 'ShortAnswerList',
  Paragraph = 'Paragraph',
  Date = 'Date',
  Time = 'Time'
}

export type TemplateElementKey = keyof typeof TemplateElements;

// Template elements that need an options list.
// Considered making an array of objects to tightly couple
// element types with info about whether they need options or not,
// but will pivot to that iff we need to couple more attributes
export const TemplateElementsWithOptions = [
  TemplateElements.SingleSelect,
  TemplateElements.MultiSelect
];

// Template elements that need a length (ex: how many answers
// to be included in a list)
export const TemplateElementsWithLength = [TemplateElements.ShortAnswerList];

export type OptArgs = { options?: string[]; lengthOfList?: number }; // needed for elements listed in TemplateElementsWithOptions

export enum QuestionParentType {
  Template = 'Template',
  MeetingNote = 'MeetingNote'
}

export type TemplateBlockCreate = {
  position: number;
  type: TemplateElements;
  question: string;
  optArgs?: OptArgs;
  // required: boolean;
  // templateId: number;
};

export type TemplateBlock = TemplateBlockCreate & { id: number; parentType: QuestionParentType };

export type TemplateBlockServer = {
  id?: number;
  position: number;
  type: TemplateElements;
  question: string;
  optArgs?: OptArgs;
};

// Representation of a single- or multi-select answer
// Ex: {red: false, blue: false, green: true}
export type OptionsAnswer = { [key: string]: boolean };

// Answer value for any answer type, ie: DateAnswer, ShortAnswer, Multi-Select Answer, etc.
export type AnswerValue = string | string[] | Date | OptionsAnswer;

// Resultant type when a Template Block is zipped with a Template Block Answer
export type TemplateBlockAndAnswer = { block: TemplateBlock; answer?: TemplateBlockAnswer };

export type TemplateBlockAnswer = {
  id: number;
  answer: AnswerValue;
  templateBlockId: number;
  meetingId: number;
  type: TemplateElements;
};

export type TemplateBlockAnswerServer = {
  id: number;
  answer: AnswerValue;
  formElementId: number; // templateBlockId
  conversationId: number; // meetingId
  type: TemplateElements;
};

export type TranscriptChunk = {
  position: number;
  speaker: string;
  text: string;
  timestamp: number | null;
};

export interface ProductTourState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
  shouldModalOpen: boolean;
}
