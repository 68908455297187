import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { EllipsisVerticalIcon, XMarkIcon } from '@heroicons/react/20/solid';
import {
  DocumentIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  TrashIcon
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { forwardRef, useEffect, useState } from 'react';
import { getIsTemplateUsed } from '../clients/templateClient';
import useNavigation from '../common/navigation';
import { Template } from '../common/types';
import { timeSince } from '../common/utils/dateUtils';
import { classNames } from '../common/utils/utils';
import { LoadingIcon } from './LoadingIcon';

interface MeetingTemplateCardProps {
  template: Template;
  onLoadingStateChange: (templateId: number, state: boolean) => void;
  deleteTemplate: (id: number) => void;
  duplicateTemplate: (templateId: number) => void;
  updateTemplate: (
    templateId: number,
    title: string,
    handleSuccess: () => void,
    handleFailure: () => void
  ) => void;
  isUsedInProductTour: boolean;
}

const MeetingTemplateCard = forwardRef<HTMLButtonElement, MeetingTemplateCardProps>(
  (
    {
      template,
      onLoadingStateChange,
      deleteTemplate,
      duplicateTemplate,
      updateTemplate,
      isUsedInProductTour
    }: MeetingTemplateCardProps,
    ref
  ) => {
    const [isTitleEditing, setIsTitleEditing] = useState(false);
    const [title, setTitle] = useState(template.title);
    const [isSaving, setIsSaving] = useState(false);
    const { goToTemplatePage } = useNavigation();

    const titleHasEdits = title !== template.title;

    // Get usage status of template
    const { data, isLoading } = useQuery({
      queryKey: ['isTemplateUsed', template.id],
      queryFn: () => getIsTemplateUsed(template.id!)
    });
    const isTemplateUsed = data && data.hasBeenUsed;

    const handleDuplication = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      duplicateTemplate(template.id!);
    };

    const markEditingComplete = () => {
      setIsTitleEditing(false);
      setIsSaving(false);
    };

    const handleTitleUpdate = () => {
      setIsSaving(true);
      updateTemplate(template.id!, title, markEditingComplete, () => setIsSaving(false));
    };

    useEffect(() => {
      onLoadingStateChange(template.id!, !isLoading);
    }, [template.id, isLoading]);

    return (
      <li className="flex items-center justify-between gap-x-6 py-6 bg-white">
        <div
          id={isUsedInProductTour ? 'first-template' : undefined}
          className="w-full flex min-w-0 space-x-4 lg:space-x-6"
        >
          <div className="flex lg:items-center">
            <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-indigo-50 rounded-full">
              <DocumentIcon className="h-5 w-5 text-indigo-500 stroke-1" />
            </div>
          </div>
          {/* <img alt="" src={person.imageUrl} className="h-12 w-12 flex-none rounded-full bg-gray-50" /> */}
          <div className="w-full flex-col sm:flex-row flex sm:space-x-6">
            <div className="min-w-0 w-full">
              <div className="w-full flex-col lg:flex-row flex lg:items-center items-start gap-x-4">
                {isTitleEditing ? (
                  <div className="relative w-full items-center">
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className="w-full pb-1.5 pr-24 mb-1 text-lg font-semibold leading-6 text-gray-600 border-b border-indigo-300 focus:ring-0 focus:outline-none focus:border-indigo-600"
                    />
                    <div className="absolute right-1 top-0 flex ">
                      {isSaving && <LoadingIcon />}
                      <button
                        disabled={!titleHasEdits || isSaving}
                        onClick={handleTitleUpdate}
                        className="ml-2 p-1 px-3 text-indigo-600 bg-indigo-50 text-xs font-semibold rounded whitespace-nowrap disabled:bg-gray-100 disabled:text-gray-400 hover:bg-indigo-500 hover:text-white focus:outline-none transition-color duration-300"
                      >
                        {/* <CheckIcon className="h-5 w-5" /> */}
                        Save
                      </button>
                      <button
                        onClick={() => {
                          setIsTitleEditing(false);
                          setTitle(template.title);
                        }}
                        className="ml-1 p-0.5 px-1 text-indigo-600 text-xs font-semibold rounded whitespace-nowrap hover:bg-indigo-500 hover:text-white focus:outline-none transition-color duration-300"
                      >
                        <XMarkIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => goToTemplatePage(template.id!, false)}
                    className="text-lg text-left font-semibold leading-6 text-gray-800 hover:text-indigo-500 transition-colors duration-300"
                  >
                    {template.title}
                  </button>
                )}
                {/* Template status pill to show if it is in use */}
                {isTemplateUsed && (
                  <div
                    className={classNames(
                      'bg-teal-100 text-teal-800',
                      // : 'bg-yellow-100 text-yellow-800',
                      // 'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                      'rounded-full bg-teal-50 px-2 py-0.5 my-1.5 lg:my-0 text-xs flex items-center font-medium text-teal-700 ring-1 ring-inset ring-teal-600/20 whitespace-nowrap'
                    )}
                  >
                    In Use
                    <div className="text-left relative group">
                      {/* Tooltip */}
                      <QuestionMarkCircleIcon
                        className="h-4 w-4 ml-1 -mr-1 text-green-800 opacity-30 hover:stroke-2 hover:opacity-70 transition-opacity transition-stroke duration-300"
                        aria-hidden="false"
                      />
                      <span className="text-wrap absolute block bottom-full bg-gray-500 left-8 top-0 h-fit w-72 text-white px-3 pt-2 pb-2.5 text-xs leading-4 rounded-lg shadow-sm opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
                        <p>
                          This template has been used to generate notes. It cannot be edited or
                          deleted.
                        </p>
                        <p className="mt-2">
                          If you need to make changes, you can duplicate this template and make
                          edits to the duplicate.
                        </p>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="lg:mt-1 flex items-center gap-x-2 text-sm leading-5 text-gray-500">
                <p className="">Created {timeSince(new Date(template.createdAt!))}</p>
                {template.updatedAt !== template.createdAt && (
                  <>
                    {' '}
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p className="">Updated {timeSince(new Date(template.updatedAt!))}</p>
                  </>
                )}
              </div>
            </div>

            <div className="lg:ml-auto flex flex-none items-center gap-x-4 mt-4 lg:mt-2 lg:mt-0">
              <button
                ref={isUsedInProductTour ? ref : undefined} // For product tour - so we can click on this button to navigate to template view
                onClick={() => goToTemplatePage(template.id!, false)}
                className="view-template-button rounded-md px-4 py-2 text-xs font-semibold text-white shadow-sm bg-indigo-600 hover:bg-indigo-500 sm:block"
              >
                View {!isTemplateUsed ? 'or Edit' : ''}
                <span className="sr-only">, {template.title}</span>
              </button>

              <button
                onClick={(e) => handleDuplication(e)}
                className="hover:cursor rounded-md px-4 py-2 text-xs font-semibold bg-indigo-50 hover:bg-indigo-500 text-indigo-800 hover:text-white sm:block disabled:text-gray-400 disabled:bg-gray-100 disabled:cursor-not-allowed"
              >
                Duplicate<span className="sr-only">, {template.title}</span>
                {/* <DocumentDuplicateIcon className="h-4 w-4 ml-1.5" /> */}
              </button>

              <Menu as="div" className="relative flex-none items-center">
                <MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
                </MenuButton>
                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <MenuItem>
                    <button
                      onClick={() => setIsTitleEditing(true)}
                      className="inline-flex items-center block w-full px-2.5 p-1 text-left text-xs font-semibold leading-6 text-gray-700 hover:bg-indigo-50 hover:text-indigo-700"
                    >
                      <PencilSquareIcon className="h-4 w-4 mr-2" />
                      Edit Title
                    </button>
                  </MenuItem>
                  <MenuItem>
                    <button
                      disabled={isTemplateUsed}
                      onClick={() => {
                        const isConfirmed = window.confirm(
                          'Are you sure you want to delete this template? You cannot undo this action.'
                        );
                        if (isConfirmed) {
                          deleteTemplate(template.id!);
                        }
                      }}
                      className="inline-flex items-center block w-full px-2.5 p-1 text-left text-xs font-semibold leading-6 text-gray-700 hover:bg-indigo-50 hover:text-indigo-700 disabled:text-gray-400 disabled:bg-white disabled:cursor-not-allowed"
                    >
                      <TrashIcon className="h-4 w-4 mr-2" />
                      Delete<span className="sr-only">, {template.title}</span>
                    </button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
        </div>
      </li>

      // <li
      //   key={template.id}
      //   className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white border border-gray-200"
      // >
      //   {/* Title Section */}
      //   <div className="flex w-full items-center justify-between space-x-6 p-6">
      //     <FolderIcon className="w-7 h-7 text-indigo-400 stroke-1" />
      //     <div className="flex-1 truncate">
      //       <div className="flex items-center space-x-3">
      //         <h3 className="truncate text-base font-medium text-gray-900">{template.title}</h3>
      //       </div>
      //     </div>
      //   </div>

      //   {/* Edit and Delete Section */}
      //   <div>
      //     <div className="-mt-px flex divide-x divide-gray-200">
      //       <div className="flex w-0 flex-1 hover:cursor-pointer">
      //         <button
      //           onClick={() => goToTemplatePage(template.id!)}
      //           className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-bl-lg border border-transparent py-2.5 font-semibold text-sm text-gray-900  opacity-60 hover:opacity-100 transition-opacity duration-300"
      //         >
      //           <PencilIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
      //           Edit
      //         </button>
      //       </div>
      //       <div className="-ml-px flex w-0 flex-1 hover:cursor-pointer hover:font-semibold">
      //         <button
      //           className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-br-lg border border-transparent py-2.5 font-semibold text-sm text-gray-900 opacity-60 hover:opacity-100 transition-opacity duration-300"
      //           onClick={() => {
      //             const isConfirmed = window.confirm(
      //               'Are you sure you want to delete this template? You cannot undo this action.'
      //             );
      //             if (isConfirmed) {
      //               deleteTemplate(template.id!);
      //             }
      //           }}
      //         >
      //           <TrashIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
      //           Delete
      //         </button>
      //       </div>
      //     </div>
      //   </div>
      // </li>
    );
  }
);

export default MeetingTemplateCard;
