import { XMarkIcon } from '@heroicons/react/20/solid';
import { CalendarIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { getClients } from '../clients/clientClient';
import {
  createMeeting,
  deleteMeetingById,
  getMeetings,
  processMeeting,
  updateMeetingById
} from '../clients/meetingClient';
import { Client, CreateMeetingRequest, Meeting, ProcessingStatus } from '../common/types';
import {
  formatDateAsLongDate,
  formatDateAsYMD,
  formatYMDAsLongDateWithWeekday
} from '../common/utils/dateUtils';
import { sortMeetings } from '../common/utils/utils';
import AddMeetingModal from '../components/AddMeetingModal';
import EmptyState from '../components/EmptyState';
import ErrorBanner from '../components/ErrorBanner';
import LoadingState from '../components/LoadingState';
import MeetingCalendar from '../components/MeetingCalendar';
import MeetingListItem from '../components/MeetingListItem';
import ProductTourWrapper from '../components/ProductTourWrapper';
import Sidebar from '../components/Sidebar';
import UploadModal, { MAX_FILE_SIZE, MAX_FILE_SIZE_MB } from '../components/UploadModal';
import { useGlobalContext } from '../context/GlobalProvider';
import { useProductTourContext } from '../context/ProductTourContext';
import { meetingNotesPageStepIndex, notePageStepIndex } from '../data/productTourSteps';
import RecordAudioButton from '../RecordAudioButton';

const _UPLOAD_ALERT_TIMEOUT = 5000;

type GroupedMeetings = { [key: string]: Meeting[] };

/**
 * Creates a new Date that is n number of days ago, with a hardcoded
 * time. This is used in the hardcoded meetings list, so that we can
 * keep the demo looking recent.
 */
// const _getPastDateWithSetTime = (daysAgo: number, timeInput: string) => {
//   const pastDate = new Date();
//   pastDate.setDate(pastDate.getDate() - daysAgo);

//   // Set hardcoded time
//   const [hours, minutes] = timeInput.split(':').map(Number);
//   pastDate.setHours(hours, minutes, 0, 0);

//   return pastDate;
// };

// TODO: get rid of these. leaving them for now since might be nice for demo
const hardcodedMeetings: Meeting[] = [];
// const hardcodedMeetings: Meeting[] = [
//   {
//     id: 0,
//     dateTime: _getPastDateWithSetTime(0, '10:45'),
//     client: { id: 100, name: 'Alex Nguyen' },
//     location: 'Napa Housing Program Office',
//     meetingTemplateId: 1
//   },
//   {
//     id: 1,
//     dateTime: _getPastDateWithSetTime(0, '10:00'),
//     client: { id: 101, name: 'Kai Sullivan' },
//     location: 'Napa Housing Program Office',
//     meetingTemplateId: 1
//   },
//   {
//     id: 2,
//     dateTime: _getPastDateWithSetTime(0, '09:00'),
//     client: { id: 102, name: 'Avery Patel' },
//     location: 'Napa Housing Program Office',
//     meetingTemplateId: 1
//   },
//   {
//     id: 3,
//     dateTime: _getPastDateWithSetTime(1, '16:00'),
//     client: { id: 103, name: 'Alex Ramirez' },
//     location: 'Napa Housing Program Office',
//     meetingTemplateId: 1
//   },
//   {
//     id: 4,
//     dateTime: _getPastDateWithSetTime(1, '14:30'),
//     client: { id: 104, name: 'Charlie Amari' },
//     location: 'Napa Housing Program Office',
//     meetingTemplateId: 1
//   },
//   {
//     id: 5,
//     dateTime: _getPastDateWithSetTime(1, '13:00'),
//     client: { id: 105, name: 'Cameron Ellis' },
//     location: 'Napa Housing Program Office',
//     meetingTemplateId: 1
//   },
//   {
//     id: 6,
//     dateTime: _getPastDateWithSetTime(1, '11:00'),
//     client: { id: 106, name: 'Jin Park' },
//     location: 'Napa Housing Program Office',
//     meetingTemplateId: 1
//   },
//   {
//     id: 7,
//     dateTime: _getPastDateWithSetTime(1, '09:30'),
//     client: { id: 107, name: 'Elena Sanchez' },
//     location: 'Napa Housing Program Office',
//     meetingTemplateId: 1
//   },
//   {
//     id: 8,
//     dateTime: _getPastDateWithSetTime(2, '16:30'),
//     client: { id: 108, name: 'Kwamei Osei' },
//     location: 'Napa Housing Program Office',
//     meetingTemplateId: 1
//   }
// ];

// Group meetings by date

export default function MeetingNotesPage() {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  // const [isUploadAlertOpen, setIsUploadAlertOpen] = useState(true);
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [filteredMeetingsGroupedByDate, setFilteredMeetingsGroupedByDate] =
    useState<GroupedMeetings>({});
  const [uploadMeetingId, setUploadMeetingId] = useState<number | null>(null); // ID of the meeting whose button was clicked to open the Upload Modal
  const [isAddMeetingModalOpen, setIsAddMeetingModalOpen] = useState(false);
  const [filterClientId, setFilterClientId] = useState<string>();
  const [filterClientName, setFilterClientName] = useState<string>();

  const buttonRef = useRef<HTMLButtonElement>(null); // For navigating to note page during product tour
  const { state, setState } = useGlobalContext();
  const queryClient = useQueryClient();

  // ============================= PRODUCT TOUR ===============================
  const { setState: setTourState, state: tourState } = useProductTourContext();

  const pauseTour = () => setTourState({ ...tourState, run: false });
  const resumeTour = () => setTourState({ ...tourState, run: true });

  // On mount, if tour is active, trigger the first step for the clients page
  useEffect(() => {
    if (tourState.tourActive) {
      pauseTour();
      const timer = setTimeout(() => resumeTour(), 600); // ms delay to ensure the tour is active before setting the step index.
      return () => clearTimeout(timer);
    }
  }, []);

  const moveToNextTourStep = (currentStepIndex: number) => {
    if (tourState.tourActive && tourState.stepIndex === currentStepIndex) {
      setTourState({ ...tourState, stepIndex: currentStepIndex + 1 });
    }
  };

  useEffect(() => {
    if (tourState.tourActive && tourState.run) {
      setIsAddMeetingModalOpen(tourState.shouldModalOpen);
    }
  }, [tourState.shouldModalOpen]);

  // If user used product tour to navigate to the template builder, trigger clicking button to
  // navigate to template view
  useEffect(() => {
    if (tourState.tourActive && tourState.stepIndex === notePageStepIndex) {
      buttonRef.current?.click();
    }
  }, [tourState.stepIndex]);
  // ============================= END PRODUCT TOUR ===============================

  // Fetch all clients for current user
  const {
    data: clients,
    error: clientsError,
    isLoading: isClientsLoading
  } = useQuery({
    queryKey: ['getClients'],
    queryFn: getClients
  });

  const getClientById = (clientId: number): Client | undefined => {
    if (!clients) return;
    const client = clients.find((client) => client.id === clientId);
    return client;
  };

  // Load saved filter from localStorage when the component mounts
  useEffect(() => {
    const savedFilter = localStorage.getItem('selectedClientId');
    if (!savedFilter) return;

    // Check if number is a valid client ID
    const filterClient = getClientById(Number(savedFilter));
    if (filterClient !== undefined) {
      setFilterClientId(savedFilter);
      setFilterClientName(filterClient.name);
    }
  }, []);

  //==================== Helpers for Meeting List ===========================
  // Forms a dictionary of meetings grouped by date
  const groupMeetingsByDate = (meetings: Meeting[]): GroupedMeetings => {
    const groupedMeetings: GroupedMeetings = {};
    for (const meeting of meetings) {
      const date = formatDateAsYMD(meeting.dateTime);
      if (groupedMeetings[date]) {
        groupedMeetings[date].push(meeting);
        groupedMeetings[date] = sortMeetings(groupedMeetings[date], true);
      } else {
        groupedMeetings[date] = [meeting];
      }
    }
    return groupedMeetings;
  };

  // Inserts a new meeting into the list of meetings in sorted order
  const insertMeeting = (newMeeting: Meeting): void => {
    setMeetings((prevMeetings: Meeting[]) => {
      return sortMeetings([...prevMeetings, newMeeting], true);
    });
  };

  const removeMeetingById = (meetingId: number): void => {
    setMeetings((prevMeetings: Meeting[]) => {
      return prevMeetings.filter((meeting) => meeting.id !== meetingId);
    });
  };

  // Update the processing status of a meeting by ID
  const updateProcessingStatusById = (meetingId: number, newStatus: ProcessingStatus): void => {
    setMeetings((prevMeetings: Meeting[]) => {
      return prevMeetings.map((meeting) =>
        meeting.id === meetingId ? { ...meeting, status: newStatus } : meeting
      );
    });
  };

  // Check meetingsGroupedByDate for a meeting by ID
  const doesMeetingExist = (meetingId: number): boolean => {
    return meetings.some((meeting) => meeting.id === meetingId);
  };

  // Update meetings to only contain meetings with given client id
  const filterMeetingsByClientId = (clientId: number): Meeting[] => {
    return meetings.filter((meeting) => meeting.client.id === clientId);
  };
  //==================== End Helpers for Meeting List ==========================

  // Retrieve meetings to display
  const {
    data: meetingsData,
    error: meetingsError,
    isLoading: isMeetingsLoading
  } = useQuery({
    queryKey: ['getMeetings'],
    queryFn: getMeetings
  });

  // ============================== Mutations =====================================
  // Create meeting mutation that is triggered on add meeting modal form submission
  const createMeetingMutation = useMutation({
    mutationFn: (newMeeting: CreateMeetingRequest) => {
      return createMeeting(newMeeting);
    },
    onSuccess: (meeting: Meeting) => {
      // Insert new meeting into dictionary of meetings
      insertMeeting(meeting);

      // Close modal
      setIsAddMeetingModalOpen(false);

      // Display toast
      setState((prevState) => ({
        ...prevState,
        meetingCreationSuccess: true
      }));
    },
    onError: () => {
      // Display toast
      setState((prevState) => ({
        ...prevState,
        meetingCreationError: true
      }));
    }
  });

  // Delete meeting by id
  const deleteMeetingMutation = useMutation({
    mutationFn: (meetingId: number) => {
      return deleteMeetingById(meetingId);
    },
    onSuccess: (_, meetingId: number) => {
      // Optimistically update list of meetings
      removeMeetingById(meetingId);
      // Display toast notification on success
      setState({ ...state, meetingDeletionSuccess: true });
    },
    onError: () => {
      // Display toast notification on error
      setState({ ...state, meetingDeletionError: true });
    }
  });

  // Processes meeting recording
  const processMeetingMutation = useMutation({
    retry: 0,
    mutationFn: (args: { meetingId: number; file: File }) => {
      return processMeeting(args.meetingId, args.file);
    },
    onMutate: () => {
      updateProcessingStatusById(uploadMeetingId!, ProcessingStatus.PROCESSING);

      // Display upload alert and trigger mock delay
      // setIsUploadAlertOpen(true);
      // setTimeout(setIsUploadAlertOpen, _UPLOAD_ALERT_TIMEOUT, false); // Hide alert after some time

      // Once the upload modal is closed and the file has been uploaded, we can forget
      // the current meeting ID. Also prevents future operations from mistakenly
      // being applied to the wrong meeting ID
      setUploadMeetingId(null);

      // Set state to indicate processing begun for toast
      setState((prevState) => ({
        ...prevState,
        hasStartedProcessing: true
      }));

      return { meetingId: uploadMeetingId };
    },
    // If upload/processing fails, hide alert and set status to Error
    onError: (_, args) => {
      updateProcessingStatusById(args.meetingId, ProcessingStatus.ERROR);
      // Set state to indicate processing error for toast
      setState((prevState) => ({
        ...prevState,
        processingError: true
      }));
    },
    // If upload/processing succeeds, mark meeting as completed
    onSuccess: (_1, _2, context) => {
      // Set state to indicate processing completion for toast
      setState((prevState) => ({
        ...prevState,
        isDoneProcessing: true
      }));
      updateProcessingStatusById(context.meetingId!, ProcessingStatus.COMPLETED);
    }
  });

  const updateMeetingMutation = useMutation({
    mutationFn: ({ meetingId, title }: { meetingId: number; title?: string }) => {
      return updateMeetingById(meetingId, undefined, undefined, undefined, title);
    },
    onSuccess: (updatedMeeting: Meeting) => {
      const updatedMeetings = meetings.map((meeting: Meeting) =>
        meeting.id === updatedMeeting.id ? { ...meeting, title: updatedMeeting.title } : meeting
      );
      setMeetings(updatedMeetings);
      queryClient.setQueryData(['getMeetings'], updatedMeetings);
      setState((prevGlobalState) => ({ ...prevGlobalState, meetingUpdateSuccess: true }));
    },
    onError: () => {
      // Display error toast
      setState((prevGlobalState) => ({ ...prevGlobalState, meetingUpdateError: true }));
    }
  });

  // ============================== End Mutations =================================

  useEffect(() => {
    let serverMeetings: Meeting[] = [];
    if (meetingsData) serverMeetings = meetingsData;

    // Combine meetings from API and hardcoded meetings
    // const allMeetings = [...serverMeetings, ...hardcodedMeetings];

    // Sort meetings by date and time
    const sortedMeetings = sortMeetings(serverMeetings, true);
    setMeetings(sortedMeetings);
  }, [meetingsData]);

  // Filter meetings by client ID
  const filterMeetings = (clientId: number) => {
    const filteredMeetings = filterMeetingsByClientId(clientId);
    const groupedMeetings = groupMeetingsByDate(filteredMeetings);
    setFilteredMeetingsGroupedByDate(groupedMeetings);
  };

  // Reset meetings to display all meetings
  const resetMeetings = () => {
    setFilteredMeetingsGroupedByDate(groupMeetingsByDate(meetings));
  };

  // If user selects a client from the filter dropdown, filter meetings by client
  useEffect(() => {
    if (filterClientId) {
      filterMeetings(Number(filterClientId));
      localStorage.setItem('selectedClientId', filterClientId.toString());
    } else {
      resetMeetings();
      localStorage.removeItem('selectedClientId');
    }
  }, [filterClientId, meetings]);

  /**
   * Handles the clicking of the "Upload Recording" button by
   * setting the ID of the meeting whose button was clicked and
   * opening the upload modal
   */
  const handleOpenUploadModal = (meetingId: number) => {
    if (doesMeetingExist(meetingId)) {
      setUploadMeetingId(meetingId);
      setIsUploadModalOpen(true);
    } else {
      alert('Something went wrong with your upload. Please try again.');
      setUploadMeetingId(null);
      setIsUploadModalOpen(false);
    }
  };

  /**
   * Updates the current meeting object (ie the meeting object
   * whose button was clicked to open the Upload Modal) to
   * indicate that a recording was uploaded
   */
  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    // Check if file size exceeds limit
    if (file && file.size > MAX_FILE_SIZE) {
      alert(`File size exceeds the ${MAX_FILE_SIZE_MB} MB limit.`);
      e.target.files = null; // Clear the input
      return;
    }
    if (file) {
      // Upload and process recording
      processMeetingMutation.mutate({ meetingId: uploadMeetingId!, file });
      setIsUploadModalOpen(false);
    }
  };

  const handleUpdateMeetingTitle = (
    meetingId: number,
    title: string,
    handleSuccess: () => void,
    handleFailure: () => void
  ) => {
    updateMeetingMutation.mutate(
      { meetingId, title },
      { onSuccess: () => handleSuccess(), onError: () => handleFailure() }
    );
  };

  const handleDeleteMeeting = (meetingId: number) => {
    deleteMeetingMutation.mutate(meetingId);
  };

  // Handles the change of the filter dropdown. Validates that
  // selected id is a valid client ID and stores the selected client's
  // ID and name in state
  const handleFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedClientId = e.target.value;
    const selectedClient = getClientById(Number(selectedClientId));
    if (selectedClient === undefined) {
      setFilterClientId('');
      return;
    }
    setFilterClientId(selectedClient.id.toString());
    setFilterClientName(selectedClient.name);
  };

  if (isMeetingsLoading)
    return (
      <>
        <Sidebar currentPageName={'Meeting Notes'} />
        <main className="lg:pl-72 items-center">
          <LoadingState />
        </main>
      </>
    );

  return (
    <ProductTourWrapper>
      <Sidebar currentPageName={'Meeting Notes'} />
      <UploadModal
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
        handleUpload={handleUpload}
      />
      <AddMeetingModal
        isOpen={isAddMeetingModalOpen}
        setIsOpen={setIsAddMeetingModalOpen}
        isCurrent={false}
        createMeetingMutation={createMeetingMutation}
      />
      <main className="lg:ml-72">
        {/* <RecordingUploadedAlert open={isUploadAlertOpen} /> */}
        <div className="px-4 py-10 lg:px-8 lg:py-6 mb-12">
          {/* Heading */}
          <div className="border-b border-gray-200 pt-4 pb-6 text-gray-800">
            <h3 className="text-3xl font-bold">Your Meeting Notes</h3>
          </div>

          <div className="xl:grid xl:grid-cols-12 xl:gap-x-16 ">
            {/* Meeting Calendar */}
            {/* <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9"> */}
            <div className="mt-10 text-center xl:col-start-9 xl:col-end-13 xl:row-start-1 xl:mt-9">
              <MeetingCalendar
                selectedDates={meetings.map((meeting) => formatDateAsLongDate(meeting.dateTime))}
              />
            </div>

            <div className="lg:col-span-7 xl:col-span-8">
              {/* If API fetch fails */}
              {meetingsError && <ErrorBanner message={meetingsError.message} />}
              {processMeetingMutation.error && (
                <ErrorBanner message={processMeetingMutation.error.message} />
              )}
              {clientsError && <ErrorBanner message={clientsError.message} />}
              {/* Meeting List */}
              {/* DB and Hardcoded meetings */}
              {/* {Object.values(meetingsGroupedByDate).length > 0 ? ( */}
              {Object.values(meetings).length > 0 ? (
                <>
                  <div className="mt-8 flex items-end justify-between">
                    {/* Create Meeting Button */}
                    <button
                      id="add-meeting-button"
                      type="button"
                      className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-color duration-300"
                      onClick={() => {
                        setIsAddMeetingModalOpen(true);
                        moveToNextTourStep(meetingNotesPageStepIndex + 1);
                      }}
                    >
                      <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                      Add Meeting
                    </button>

                    {/* Filter buttons */}
                    <div className="flex inline-flex items-center">
                      <label htmlFor="filter" className="text-sm mr-2">
                        Filter by
                      </label>
                      {/* If clients are still loading, display loading state */}
                      {isClientsLoading ? (
                        <LoadingState />
                      ) : (
                        <>
                          <select
                            id="filter"
                            name="filter"
                            className="max-w-40 border-b border-indigo-400 font-semibold text-gray-900 text-sm py-1 focus:outline-none focus-visible:outline-none"
                            // className="rounded-full border border-indigo-400 bg-indigo-50 font-semibold text-indigo-900 text-sm px-3 py-1"
                            onChange={handleFilterChange}
                            value={filterClientId}
                          >
                            <option value="">All Clients</option>
                            {clients?.map((client) => (
                              <option value={client.id}>{client.name}</option>
                            ))}
                          </select>
                          {/* Show clear filter button if a filter is currently selected */}
                          {!!filterClientId && (
                            <button
                              type="button"
                              className="inline-flex items-center rounded-md bg-indigo-50 p-1 pl-2 ml-3.5 text-xs font-semibold text-indigo-800 shadow hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-color duration-300"
                              onClick={() => setFilterClientId('')}
                            >
                              Clear
                              <XMarkIcon className="ml-1 h-3.5 w-3.5" aria-hidden="true" />
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {/* Meetings list */}
                  {/* <ol className="mt-6 divide-y divide-gray-100 text-sm leading-6">
                    {meetings.map((meeting) => (
                      <MeetingListItem
                        key={meeting.id}
                        meeting={meeting}
                        handleOpenUploadModal={handleOpenUploadModal}
                        deleteMeeting={handleDeleteMeeting}
                      />
                    ))}
                  </ol> */}
                  <div className="mt-12 space-y-6">
                    {Object.values(filteredMeetingsGroupedByDate).length > 0 ? (
                      Object.keys(filteredMeetingsGroupedByDate)
                        .sort()
                        .reverse() // Sort by date in descending order
                        .map((date: string) => (
                          <div key={date}>
                            {/* Display date as a header */}
                            <h2 className="pb-2 text-base border-b border-gray-200 font-medium text-gray-800">
                              {formatYMDAsLongDateWithWeekday(date)}
                            </h2>
                            {/* Display all meetings for the current date */}
                            <ol className="divide-y divide-gray-100 text-sm leading-6">
                              {filteredMeetingsGroupedByDate[date].map(
                                (meeting: Meeting, index: number) => (
                                  <MeetingListItem
                                    ref={buttonRef}
                                    isUsedInProductTour={index === 0} // We arbitrarily navigate to the first note during the product tour
                                    key={meeting.id}
                                    meeting={meeting}
                                    handleOpenUploadModal={handleOpenUploadModal}
                                    updateMeetingById={handleUpdateMeetingTitle}
                                    deleteMeeting={handleDeleteMeeting}
                                  />
                                )
                              )}
                            </ol>
                          </div>
                        ))
                    ) : (
                      <div className="text-gray-700">
                        You have no meetings{' '}
                        {filterClientName && (
                          <>
                            with <span className="font-bold">{filterClientName} </span>
                          </>
                        )}{' '}
                        yet.
                      </div>
                    )}
                  </div>
                </>
              ) : (
                // Empty state
                <div className="w-full">
                  <EmptyState
                    title="No meetings"
                    description="Get started by creating your first meeting!"
                    icon={CalendarIcon}
                    buttonText="Create Meeting"
                    onClick={() => setIsAddMeetingModalOpen(true)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <RecordAudioButton />
      </main>
    </ProductTourWrapper>
  );
}
