import { XMarkIcon } from '@heroicons/react/20/solid';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { TranscriptChunk } from '../common/types';
import { classNames } from '../common/utils/utils';
import TranscriptSearchBar from './TranscriptSearchBar';
import TranscriptSourceBar from './TranscriptSourceBar';

// TODO: Figure out why concatenating these makes the highlight color sporadically disapperar
// const BG_COLOR = "[#e8edff]";
// const BG_COLOR = "yellow-100";

interface TranscriptColumnProps {
  isTranscriptSourceBarOpen: boolean;
  setIsTranscriptSourceBarOpen: (isOpen: boolean) => void;
  transcriptSourceIndices: number[];
  transcriptChunks: TranscriptChunk[];
}

const setWindowHash = (index: number) => {
  window.location.hash = '#transcript-line-' + index;
};

function formatTimestamp(timeStampInMilliseconds: number | null): string | null {
  if (timeStampInMilliseconds === null) {
    return null;
  }

  const seconds = Math.floor(timeStampInMilliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const remainingSeconds = seconds % 60;
  const remainingMinutes = minutes % 60;

  const formattedMinutes = String(remainingMinutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  // If hours are zero, omit them from the formatted time
  if (hours === 0) {
    return `${formattedMinutes}:${formattedSeconds}`;
  } else {
    const formattedHours = String(hours).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
}

export default function TranscriptColumn({
  isTranscriptSourceBarOpen,
  setIsTranscriptSourceBarOpen,
  transcriptSourceIndices,
  transcriptChunks
}: TranscriptColumnProps) {
  // A value of -1 indicates that no line is highlighted
  const [highlightedLineIndex, setHighlightedLineIndex] = useState(-1);
  // State to manage the visibility of the transcript aside. Always visible on larger screens
  const [isTranscriptVisible, setIsTranscriptVisible] = useState(false);
  // Search state
  const [searchTerm, setSearchTerm] = useState('');

  // Highlight text that matches the current search term
  const getHighlightedText = (text: string, query: string) => {
    if (!query) {
      return text;
    }
    // Escape special characters in the query string
    const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    // Split the text based on the escaped query
    const parts = text.split(new RegExp(`(${escapedQuery})`, 'gi'));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="bg-indigo-500 text-white">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  // If the transcript source bar is open, the transcript should be visible
  useEffect(() => {
    if (isTranscriptSourceBarOpen) {
      setIsTranscriptVisible(true);
    }
  }, [isTranscriptSourceBarOpen]);

  return (
    <>
      <aside
        id="transcript-column"
        className={classNames(
          isTranscriptVisible
            ? 'translate-x-0 xl:translate-x-0'
            : 'translate-x-full xl:translate-x-0',
          'print:hidden flex flex-col transform transition-transform duration-300 ease-in-out fixed z-30 bg-white inset-y-0 right-0 w-96 border-l border-gray-200'
        )}
      >
        <TranscriptSourceBar
          sourceIndices={transcriptSourceIndices}
          setHighlightedLineIndex={(index) => setHighlightedLineIndex(index)}
          setIsOpen={(isOpen) => setIsTranscriptSourceBarOpen(isOpen)}
          isOpen={isTranscriptSourceBarOpen}
          setWindowHash={setWindowHash}
        />
        <TranscriptSearchBar
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          highlightedLineIndex={highlightedLineIndex}
          setHighlightedLineIndex={setHighlightedLineIndex}
          transcriptChunks={transcriptChunks}
          setWindowHash={setWindowHash}
        />

        <div className="pt-4 flex-1 overflow-y-auto grow">
          {/* Heading */}
          <div className="flex pb-5 py-4 px-4 sm:px-6 lg:px-8 align-items">
            <h3 className="text-xl font-bold leading-6 text-gray-700">Transcript</h3>
            <button
              className="ml-auto xl:hidden text-gray-400 hover:text-indigo-600 transition-color duration-300"
              onClick={() => {
                setIsTranscriptVisible(false);
                setIsTranscriptSourceBarOpen(false);
              }}
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>

          {/* Transcript Body */}
          <ul className="text-sm divide-y divide-gray-100 text-gray-700 border-t border-gray-100 mb-4">
            {transcriptChunks.map((chunk, index) => (
              <li
                key={chunk.position}
                id={'transcript-line-' + chunk.position}
                // Highlight the current transcript line if the index matches highlightedLineIndex
                className={`gap-x-4 py-4 px-4 sm:px-6 lg:px-8 w-full ${index === highlightedLineIndex ? 'bg-[#e8edff]' : ''}`}
              >
                <p className="font-semibold mb-0.5 flex items-center">
                  {chunk.speaker}{' '}
                  <span className="ml-3 text-xs text-gray-400 font-normal">
                    {formatTimestamp(chunk.timestamp)}
                  </span>
                </p>
                {/* <span className="font-semibold">{chunk.speaker}</span>:{' '} */}
                {getHighlightedText(chunk.text, searchTerm)}
              </li>
            ))}
          </ul>
        </div>
      </aside>
      {/* For smaller screens */}
      {!isTranscriptVisible && (
        <div className="fixed z-40 group bottom-12 right-8 xl:hidden print:hidden">
          {' '}
          <button
            onClick={() => {
              setIsTranscriptVisible(!isTranscriptVisible);
            }}
            className="flex-row items-center rounded-full bg-indigo-600 border-4 border-indigo-200 hover:bg-indigo-600 text-indigo-700 text-white hover:text-white shadow-lg shadow-slate-300 p-3 justify-center transition-color duration-300"
          >
            <ChatBubbleLeftRightIcon className="stroke-1 h-7 w-7 z-50" />
            {/* <p className="text-[10px] font-medium">Transcript</p> */}
          </button>
          {/* <span className="absolute block bottom-full top-13 bg-white h-fit w-36 text-gray-700 px-2 pt-2 pb-2.5 text-xs leading-4 rounded-lg shadow-lg opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-opacity duration-300">
          View Transcript
        </span> */}
        </div>
      )}
    </>
  );
}
